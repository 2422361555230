import React from "react"
import {
  graphql,
  Link as GatsbyLink
} from "gatsby"

import { 
  Container, 
  Heading,
  Link,
  Text,
} from "@chakra-ui/react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Providers from "../components/providers"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Providers>
      <Layout location={location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <Container maxW="container.lg" textAlign="center" pt="48" pb="32">
          <Heading as="h1" size="2xl" itemProp="headline">404: Not Found</Heading>
          <Text mt="2">You page you're looking for doesn&#39;t exist. Try going back to the <Link as={GatsbyLink} to="/" textDecoration="underline">homepage</Link> instead.</Text>
        </Container>
      </Layout>
    </Providers>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
